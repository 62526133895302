import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
// Models
import { InventoryEntities } from 'client/data/models/inventory';

const VEHICLE_NAME_ICONS = {
  electric: 'icon-power2',
  'plug-in hybrid': 'icon-power-cord-thin',
};

export function VehicleNameDisplay({ vehicle, firstLine, secondLine }) {
  const engine = get(vehicle, 'vehicleInfo.partsInfo.engineType');

  return (
    <>
      <div className="size-16 text-cool-gray-10 font-weight-bold mb-0_5">
        {VEHICLE_NAME_ICONS[engine] && (
          <i
            className={classnames(VEHICLE_NAME_ICONS[engine], 'size-16 text-turquoise-40')}
            style={{
              display: 'inline-block',
              width: '1rem',
              height: '1rem',
              marginRight: '0.25rem',
            }}
            aria-hidden
          />
        )}
        {firstLine}
      </div>
      <div className="font-weight-normal size-14 text-cool-gray-30">{secondLine}</div>
    </>
  );
}

VehicleNameDisplay.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
};

VehicleNameDisplay.defaultProps = {
  firstLine: '',
  secondLine: '',
};
