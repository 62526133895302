import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { InventoryEntities } from 'client/data/models/inventory';
import { PricingDetails as PricingDetailsSRP } from 'site-modules/shared/components/usurp-inventory-card/pricing-details-srp';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { ClickableCardLink } from 'site-modules/shared/components/inventory/clickable-card/clickable-card-link/clickable-card-link';
import { UsurpInventoryCardVdpLink } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-vdp-link';

export function UsurpInventoryCardInfo({
  vehicle,
  selectedPaymentType,
  ariaLabel,
  includeDeliveryFee,
  vehicleNameDisplay,
  vdpLink,
  displayBelowMarketCopy,
  isLeaseCardWithLeasePayment,
  isLoanCardWithLoanPayment,
  isShop2901Enabled,
}) {
  const { type } = vehicle;
  const isNewType = isNew(type);

  return (
    <Row className="info-wrapper mx-0 pb-0_75 flex-nowrap justify-content-between">
      <Col xs={7} className="left-section py-0 pl-0 pr-0_5">
        <h2 className="mb-0">
          <ClickableCardLink
            linkComponent={UsurpInventoryCardVdpLink}
            className="wrapping-vdp-link"
            vehicle={vehicle}
            vdpLink={vdpLink}
            ariaLabel={ariaLabel}
            isShop2901Enabled={isShop2901Enabled}
          >
            {vehicleNameDisplay}
          </ClickableCardLink>
        </h2>
      </Col>
      <Col xs={5} className="pr-0 text-right d-flex justify-content-between">
        <div className="border-left h-100 pl-0_5" />
        {isLeaseCardWithLeasePayment ? (
          <PricingDetailsSRP
            vehicle={vehicle}
            displayBelowMarketCopy={displayBelowMarketCopy}
            displayLeaseMonthlyPayment
            displayDealBadge={false}
          />
        ) : (
          <PricingDetailsSRP
            vehicle={vehicle}
            displayBelowMarketCopy={!isLoanCardWithLoanPayment && displayBelowMarketCopy}
            displayDealBadge
            displayLoanMonthlyPayment={isLoanCardWithLoanPayment}
            displayMSRP={isNewType && !selectedPaymentType}
            includeDeliveryFee={includeDeliveryFee}
          />
        )}
      </Col>
    </Row>
  );
}

UsurpInventoryCardInfo.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  selectedPaymentType: PropTypes.string,
  ariaLabel: PropTypes.string,
  includeDeliveryFee: PropTypes.bool,
  vehicleNameDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  vdpLink: PropTypes.string,
  displayBelowMarketCopy: PropTypes.bool,
  isLeaseCardWithLeasePayment: PropTypes.bool,
  isLoanCardWithLoanPayment: PropTypes.bool,
  isShop2901Enabled: PropTypes.bool,
};

UsurpInventoryCardInfo.defaultProps = {
  selectedPaymentType: '',
  ariaLabel: '',
  includeDeliveryFee: false,
  vehicleNameDisplay: '',
  vdpLink: '',
  displayBelowMarketCopy: false,
  isLeaseCardWithLeasePayment: false,
  isLoanCardWithLoanPayment: false,
  isShop2901Enabled: false,
};
