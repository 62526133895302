import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import { InventoryEntities } from 'client/data/models/inventory';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { FOUND_VIN_SCREEN_CREATIVE_ID } from 'site-modules/shared/constants/inventory/llm-suggested-facets';
import { Link } from 'site-modules/shared/components/link/link';

export function UsurpInventoryCardVdpLink({
  vehicle,
  isSimilarCards,
  isVdpPartnerListing,
  disabled,
  isLlmCard,
  searchId,
  vdpLink,
  onFocus,
  onBlur,
  tabIndex,
  children,
  ariaLabel,
  ariaHidden,
  className,
  isShop2901Enabled,
  onClick,
}) {
  function handleVdpLinkClick(event) {
    if (disabled) {
      event.preventDefault();
    } else {
      const {
        dealerInfo: { franchiseId = '', rooftopId = '' },
      } = vehicle;

      let creativeId = 'edm-entry-vehicle-card-list';

      if (isVdpPartnerListing) {
        creativeId = 'edm-entry-dealer-conquest';
      } else if (isSimilarCards && !isLlmCard) {
        creativeId = 'edm-entry-conquest';
      } else if (isLlmCard) {
        creativeId = FOUND_VIN_SCREEN_CREATIVE_ID;
      }

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_VIEW_VIN_DETAILS,
          subaction_name: TrackingConstant.ACTION_VIEW_VIN_DETAILS,
          action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          creative_id: creativeId,
          ...(isSimilarCards && !isLlmCard
            ? { partner_id: franchiseId }
            : { value: isLlmCard ? `srp_search_id=${searchId}` : 'vehicle_card_click' }),
          ...(!isLlmCard ? { loc_id: rooftopId, f_id: franchiseId } : {}),
        },
      });

      onClick(event);
    }
  }

  return (
    <Link
      className={classNames('usurp-inventory-card-vdp-link', className)}
      to={vdpLink}
      rel="nofollow"
      onClick={handleVdpLinkClick}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      {...(isShop2901Enabled ? { target: '_blank' } : {})}
    >
      {children}
    </Link>
  );
}

UsurpInventoryCardVdpLink.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  vdpLink: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  tabIndex: PropTypes.number,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  ariaHidden: PropTypes.bool,
  searchId: PropTypes.string,
  isSimilarCards: PropTypes.bool,
  isLlmCard: PropTypes.bool,
  isVdpPartnerListing: PropTypes.bool,
  disabled: PropTypes.bool,
  isShop2901Enabled: PropTypes.bool,
  onClick: PropTypes.func,
};

UsurpInventoryCardVdpLink.defaultProps = {
  tabIndex: undefined,
  ariaLabel: undefined,
  ariaHidden: undefined,
  searchId: undefined,
  onFocus: noop,
  onBlur: noop,
  onClick: noop,
  isSimilarCards: false,
  isLlmCard: false,
  isVdpPartnerListing: false,
  disabled: false,
  children: null,
  isShop2901Enabled: false,
};
